import LandingButton from './LandingButton';

import { styled } from '@styles/stitches.config';

const FAQ = () => {
  return (
    <Container>
      <Content>
        <Title>{'더 궁금하신 점이 있나요? 💁'}</Title>
        <Buttons>
          <LandingButton
            text={'FAQ 바로가기'}
            theme={'black'}
            linkTo={'https://kemi.oopy.io/'}
          />
          <LandingButton
            text={'담당자에게 문의하기 💬'}
            theme={'black'}
            linkTo={'https://kemi.channel.io/support-bots/40446'}
          />
        </Buttons>
      </Content>
    </Container>
  );
};

const Container = styled('div', {
  marginTop: 240,
  marginBottom: 120,
  padding: '64px 24px',
  borderRadius: 40,
  background: 'linear-gradient(180deg, #E6BEFF 0%, #BEDCFF 100%)',
});

const Content = styled('div', {
  width: '100%',
  maxWidth: 320,
  margin: '0 auto',
});

const Title = styled('h2', {
  fontType: 'heading3',
  marginBottom: 24,
});

const Buttons = styled('div', {
  display: 'grid',
  gap: 8,
});

export default FAQ;
