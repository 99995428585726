import ROUTE from '@routers';
import Text from '@shared/components/Text';
import { styled } from '@styles/stitches.config';

type Props = {
  transparent?: boolean;
};

function Footer({ transparent }: Props) {
  return (
    <Box transparent={transparent}>
      <Text type={'subtitleBold'} color={'$grey100'}>
        {'와이어드컴퍼니(주)'}
      </Text>
      <Contents>
        <List>
          <Label>{'대표이사'}</Label>
          <Value>{'홍만의, 황봄님'}</Value>
        </List>
        <List>
          <Label>{'사업자등록번호'}</Label>
          <Value>{'631-87-01013'}</Value>
          <a href={ROUTE.businessInfo} target={'_blank'} rel={'noreferrer'}>
            <BusinessInfo>{'[사업자정보 확인]'}</BusinessInfo>
          </a>
        </List>
        <List>
          <Label>{'통신판매업신고'}</Label>
          <Value>{'2018-서울강남-02902'}</Value>
        </List>
        <List>
          <Label>{'주소정보'}</Label>
          <Value>{'서울특별시 강남구 테헤란로 518, 10층, 13층'}</Value>
        </List>
        <List>
          <Label>{'전화번호'}</Label>
          <Value>{'1533-2909'}</Value>
        </List>
      </Contents>
      <Disclaimer>
        {
          '케미에서 판매되는 상품 중에는 개별 판매자가 판매하는 상품이 포함되어 있습니다. 개별 판매자가 판매하는 상품의 경우, 케미는 통신판매중개자로서 거래 당사자가 아니며, 판매자가 등록한 상품정보 및 거래 등에 대해 책임을 지지 않습니다.'
        }
      </Disclaimer>
      <Terms>
        <a href={ROUTE.terms} target={'_blank'} rel={'noreferrer'}>
          <Value>{'서비스 이용약관'}</Value>
        </a>
        <Divider />
        <a href={ROUTE.privacy} target={'_blank'} rel={'noreferrer'}>
          <Value>{'개인정보처리방침'}</Value>
        </a>
      </Terms>
    </Box>
  );
}

const Box = styled('div', {
  background: '$white',
  variants: {
    transparent: {
      true: {
        background: 'none',
      },
    },
  },
});

const Contents = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  marginTop: 20,
});

const List = styled('div', {
  display: 'flex',
});

const Label = styled('div', {
  width: 96,
  fontType: 'captionRegular',
  color: '$grey100',
});

const Value = styled('div', {
  fontType: 'captionRegular',
  color: '$grey100',
});

const Disclaimer = styled('div', {
  fontType: 'captionRegular',
  color: '$grey100',
  marginTop: 16,
});

const Terms = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginTop: 16,
});

const Divider = styled('div', {
  width: 1,
  height: 12,
  marginX: 8,
  backgroundColor: '$grey50',
});

const BusinessInfo = styled('div', {
  fontType: 'captionBold',
  color: '$grey100',
  marginLeft: 8,
});

export default Footer;
